export const slides = [
  {
    id: 1,
    title: "",
    description: "",
    bg_img: "bg_slide1.webp",
    href: "1",
    style_options: "margin-left: 60%",
    man_img: 'laptop_slide_man.png'
  },

  {
    id: 3,
    title: "Сначала Vedexx платит за вас\n" +
      "Затем вы оплачиваете Vedexx",
    description: "Это позволяет кредитовать клиентов сервиса, предоставляя отсрочки платежей",
    bg_img: "bg_slide_finance.webp",
    href: "12",
    style_options: "margin-left: 50%",
    man_img: "coins.webp",
  },

  {
    id: 4,
    title: "Лучшее предложение из Китая",
    description: "Доставка контейнера",
    bg_img: "bg_slide_china.webp",
    href: "1",
    style_options: null,
    ports: [
      {
        name: "Yantian",
        id: 16
      },
      {
        name: "Ningbo",
        id: 2
      },
      {
        name: "Qingdao",
        id: 8
      },
      {
        name: "Shanghai",
        id: 4
      },
    ],
    rates: [
      {
        place: "Москва",
        price: "417 475",
        place_to_id: 72,
        place_from_id: 2,
      },

      {
        place: "Санкт-Петербург",
        price: "391 358",
        place_to_id: 65,
        place_from_id: 2,
      },

      {
        place: "Екатеринбург",
        price: "422 736",
        place_to_id: 74,
        place_from_id: 2,
      },
    ]
  },

  {
    id: 2,
    title: "Vedexx — вместо брокера",
    description: "Таможенное оформление",
    bg_img: "bg_slide3.png",
    href: "2",
    style_options: "margin-left: 25%",
    man_img: "TO_slide_people.png",
  },

  {
    id: 5,
    title: "Лучшее предложение из Индии",
    description: "Контейнерные перевозки",
    bg_img: "bg_slide_india.webp",
    href: "1",
    style_options: null,
    man_img: "",
    ports: [
      {
        name: "Nhava Sheva",
        id: 118
      },
      {
        name: "Mundra",
        id: 39
      },
      {
        name: "Chennai",
        id: 46
      },
      {
        name: "Calcutta",
        id: 4757
      }
    ],

    rates: [
      {
        place: "Москва",
        price: "417 475",
        place_to_id: 72,
        place_from_id: 2,
      },

      {
        place: "Санкт-Петербург",
        price: "391 358",
        place_to_id: 65,
        place_from_id: 2,
      },

      {
        place: "Екатеринбург",
        price: "422 736",
        place_to_id: 74,
        place_from_id: 2,
      },
    ]
  },

]