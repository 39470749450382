<script>
import RateTableSlide from "./RateTableSlide.vue";
export default {
  components: {
    RateTableSlide,
  },
  
  props: {
    slides: {
      type: Array, Object
    }
  },
  
  data () {
    return {
      cycle: false,
      windowWidth: window.innerWidth,
      slideHeight: '870px',
      activeSlide: 0,
    }
  },
  
  computed: {
    windowSize() {
      return document.documentElement.clientWidth;
    },
    
    slideList() {
      if (this.windowSize < 1200)
      return this.slides.slice(1,3)
      else { return this.slides }
    },
  },
  
  methods: {
    changeCycle() {
      // this.cycle = !this.cycle
    },
    
    handleSlideChange() {
      this.slideHeight = '870px'
    },
    
    openLandingLink(slide) {
      if (slide.id === 7) {
        window.open(slide.href, '_blank')
      }
    },
    
    openSlide(data) {
      this.slideHeight = data.size
    }
  }
}
</script>
<template>
  <div
    class="info-slider"
    @click="changeCycle">
    
    <v-carousel
      :cycle="cycle"
      interval="16000"
      class="info-slider__carousel"
      :height="slideHeight"
      hide-delimiter-background
      show-arrows-on-hover
      v-model="activeSlide"
      @change="handleSlideChange"
      delimiter-icon="mdi-circle-medium"
      eager
    >
      <v-carousel-item
        v-for="(slide, i) in slideList"
        :key="i"
        class="info-slider__carousel__item"
        :ripple="false"
      >
      <a v-if="slide.id == 2" target="_blank" href="https://customs.vedexx.com/?utm_source=vedexx&utm_medium=banner" class="info-slider__carousel__item-link">
        <div
          v-show="!slide.rates && slide.id !== 'add' && slide.id !== 7"
          class="info-slider__carousel__item-man"
          :class="[Number(slide.id) === 2 ? 'info-slider__carousel__item-man-two' : '',
           Number(slide.id) === 3 ? 'info-slider__carousel__item-coins' : '' ]"
          :style="slide.style_options"
        >
          <v-img eager :src="`/slide-images/${slide.man_img}`" loading="lazy" decoding="async"/>
        </div>
      </a>

      <div 
          v-else
          v-show="!slide.rates && slide.id !== 'add'"
          class="info-slider__carousel__item-man"
          :class="[Number(slide.id) === 2 ? 'info-slider__carousel__item-man-two' : '',
           Number(slide.id) === 3 ? 'info-slider__carousel__item-coins' : '' ]"
          :style="slide.style_options"
        >
          <v-img eager :src="`/slide-images/${slide.man_img}`" loading="lazy" decoding="async"/>
        </div>

       
        
        <div
          class="info-slider__carousel__item-block"
          :title="slide.title"
        >
          <v-img
            eager
            class="info-slider__carousel__item-block-img"
            src="../../../../public/slide-images/bg_slide1_preload.webp"
          />
        </div>

        <div
          class="info-slider__carousel__item-block"
          :title="slide.title"
        >
          <v-img
            eager
            class="info-slider__carousel__item-block-img"
            decoding="async"
            :src="`/slide-images/${slide.bg_img}`"
          />
          
        </div>


        <div
          v-if="slide.id === 1"
          class="info-slider__carousel__item-laptop"
        >
          <img src="../../../../public/slide-images/laptop_rate.webp" alt="laptop">
        </div>
        
        <div class="info-slider__carousel__item-content g-container">
          <div
            class="info-slider__carousel__item-shape g-col-md-4 g-col-sm-4 g-col-xs-2 g-col-lg-3"
            v-if="slide.id === 1"
          >
            <div class="info-slider__carousel__item-shape__title">
              Нам <p class="info-slider__carousel__item-content-p">важно</p>, чтобы вы снизили финансовые и временные затраты на контейнерную логистику.
            </div>
            <div class="info-slider__carousel__item-shape__subtitle">
              <p>
                Мы создали Vedexx, чтобы вы получили лучшие варианты за 5 секунд
              </p>
            </div>
            <div class="info-slider__carousel__item-shape__list">
              Анализируйте
              <br>
              Выбирайте
              <br>
              Перевозите
            </div>
          </div>
          
          <div class="info-slider__carousel__item-content-h2 g-row ">
            <h2 class="g-col-xs-2">{{ slide.title }}</h2>
          </div>
          <div class="info-slider__carousel__item-content-h3">
            <h3 v-if="slide.id === 3">
              Vedexx оперирует собственным капиталом в размере <p class="info-slider__carousel__item-content-p">миллиард рублей</p>
            </h3>
            <h3> {{ slide.description }}</h3>
          </div>
          
          <RateTableSlide
            class="g-container"
            @openSlide="openSlide"
            v-if="slide.ports"
            :slide="slide"
          />
        </div>
      </v-carousel-item>
    </v-carousel>
  </div>
</template>

<style scoped lang="scss">
.info-slider {
  width: 100%;
  //cursor: pointer;
  
  &__carousel {
    
    &__item {
      color: #FFFFFF;
      display: flex;
      align-items: center;
      justify-content: center;
      
      &-man {
        height: 93%;
        position: relative;
        bottom: -7%;
        z-index: 1;
        
        @media (min-width: 1900px) {
          bottom: -7%;
          max-width: 33%;
        }
        
        @media (min-width: 1750px) and (max-width: 1899px) {
          max-width: 90%;
          bottom: -7%;
        }
        
        @media (max-width: $tablet-width) {
          height: 90%;
          position: absolute;
        }
        
        @media (max-width: 743px) {
          display: none;
        }
      }
      
      &-man-two {
        
        @media (min-width: 1900px) {
          bottom: -7%;
          max-width: 60%;
        }
        
        @media (min-width: $tablet-width) and (max-width: 1500px) {
          bottom: -13%;
        }
      }
      
      &-coins {
        @media (min-width: 1900px) {
          bottom: -5%;
          max-width: 39%;
          max-height: 200px;
        }
      }
      
      &-laptop {
        position: absolute;
        bottom: -10px;
        left: 20%;
        width: 70%;
        
        > img {
          height: 544px;
        }
      }
      
      &-shape {
        background: var(--main-bg-color);
        filter: drop-shadow(0px 3.795px 3.795px rgba(0, 0, 0, 0.25));
        margin-top: -110px;
        height: 500px;
        left: 0;
        display: flex;
        flex-direction: column;
        position: relative;
        padding: $size-44;
        
        &::after {
          display: block;
          content: '';
          width: 100%;
          height: 50px;
          background: var(--main-bg-color);
          position: absolute;
          bottom: 0;
          left: 0;
          transform-origin: left;
          transform: translateY(100%) skewY(175deg);
        }
        
        &__title {
          @include font-headline-2;

          line-height: 32px; 
          color: #FFFFFF;
          margin: $size-12 0;
        }
        
        &__subtitle {
          > p {
           
            color: #FFFFFF;
            margin: $size-12 0;
            box-decoration-break: clone;
            background: var(--main-orange);
            padding: 2px 0;
            display: inline;
            text-wrap: balance;
            box-shadow: 0.250em 0 0 var(--main-orange),-0.250em 0 0 var(--main-orange);
            
            @include font-body-bold-3;
          }
        } 
        
        &__list {
          @include font-headline-1;

          color: #FFFFFF;
          margin: 10px 0;
        }
      }
      
      &-content {
        width: 100%;
        z-index: 20;
        top: 230px;
        position: absolute;
        text-wrap: balance;
        padding: calc(2% + 10px) $size-16;
        left: 50%;
        transform: translateX(-50%);
        
        @media (min-width: 1920px) {
          padding: calc(2% + 10px) $size-64;
          max-width: 1696px;
        }
        
        @media (min-width: 1400px) {
          padding: calc(2% + 10px) $size-64;
        }
        
        @media (min-width: 1500px) {
          padding: 2% $size-64;
        }
        
        @media (min-width: 1600px) {
          padding: 2% $size-64;
        }
        
        &__transrussia {
          display: flex;
          position: relative;
          
          @media (min-width: 744px) and (max-width: $tablet-width) {
            //left: $size-32;
            width: 130%;
          }
          
          @media (max-width: 767px) {
            //left: $size-16;
          }
        }
        
        &-h2 {
          @include font-headline-1;

          position: relative;
          display: flex;
          justify-content: space-between;
          color: #FFFFFF;
          z-index: 30;

          margin: 0 0 15px;

          > h2:first-child {
            z-index: 30;
            position: relative;
            
            @media (max-width: 767px) {
              font-size: 30px;
              line-height: 32px;
            }
          }
          
          > h2 {
            z-index: 30;
            letter-spacing: 0.01em;
          }
        }
        
        &-h3 {
          @include font-headline-1;
          
          position: relative;
          display: block;
          color: #FFFFFF;

          > h3 {
            @include font-headline-1;
          }
        }
        
        
        &-p {
          @include font-body-1;

          color: #FFFFFF;
          box-decoration-break: clone;
          background: var(--main-orange);
          display: inline;
          box-shadow: 0.250px 0 0 var(--main-orange),-0.250px 0 0 var(--main-orange);
        }
        
        
        &-btn {
          margin: 25px 0 0;
          position: absolute !important;
          height: 60px !important;
          letter-spacing: 0.01em;
          text-transform: none;
          font-weight: 700;
          font-size: 16px;
        }
        
        &__rates {
          margin: auto;
          position: relative;
          width: 100%;
        }
      }
      
      &-block {
        height: 100%;
        width: 120%;
        position: absolute;
        left: -10%;
        top: 18%;
        bottom: 10%;
        z-index: 0;
        background-size: cover;
        
        &-img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      &-link{
        position: relative;
        z-index: 100;
      }
    }
  }
  
  &__footer {
    background: var(--grey-light);
    position: absolute;
    height: 100px;
    width: 100%;
    bottom: 0;
    left: 0;
    
    @media (max-width: 600px) {
      display: none;
    }
  }
}

.dialog__full-card {
  display: flex;
  justify-content: center;
  flex-direction: column;
  
  > img {
    height: 100%;
    width: auto;
  }
}

.dialog__full-img {
  position: relative;
  width: auto;
  height: 80vh;
}

.color-orange {
  color: var(--main-orange)
}

.v-window {
  &-x-transition,
  &-x-reverse-transition,
  &-y-transition,
  &-y-reverse-transition {
    &-enter-active,
    &-leave-active {
      transition: 1.4s cubic-bezier(0.25, 0.8, 0.5, 1) !important;
    }
  }
}

ul {
  list-style-type: none;
}
</style>
