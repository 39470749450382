<script>
import { bus } from "@/router/bus";
import HistoryRateSearch from "./HistoryRateSearch.vue";
import PublicHolidaysBtn from "./PublicHolidaysBtn.vue";
export default {
  name: "FindRatePanel.vue",
  
  components: {
    HistoryRateSearch,
    PublicHolidaysBtn
  },
  
  data() {
    return {
      searchInput: null,
      lang: 'en',
      searchType: 'fn_get_ratelist_json',
      historyData: [],
      historyString: [],
      
      showHistory: false,
      autoUpdate: true,
      place_from: [],
      place_to: [],
      unit_code: [],
      isUpdating: false,
      places_from: [],
      places_to: [],
      unit_codes: [],
      country_from: null,
      country_to: [],
      fltr_places_from: [],
      fltr_places_to: [],
      on_date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      dateFormatted: null,
      menu1: false,
      
      windowWidth: window.innerWidth,
      holidaysData: [],

      searchParams: {},
    }
  },
  
  computed: {
    windowSize() {
      return document.documentElement.clientWidth;
    },

    currentUserId(){
      return +this.$_getsetting('client_id')
    },
  },
  
  watch: {
    isUpdating(val) {
      if (val) {
        setTimeout(() => (this.isUpdating = false), 3000)
      }
    },
    on_date() {
      this.dateFormatted = this.formatDate(this.on_date)
    },
    
    searchInput(str) {
      if (!str) {
        return
      }
      this.checkLang(str)
    },
  },
  
  mounted() {
    window.addEventListener('resize', this.handleResize);
    
    this.getreferencedata('lgst_place_from_wg')
    this.getreferencedata('lgst_place_to_wg')
    this.getreferencedata('lgst_cont_types')
    
    this.getSearchParamsHistory()
    
    bus.$on("changeplacefrom", (data) => {
      this.changePlaceFrom(data)
    });
    
    bus.$on("changeplaceto", (data) => {
      this.changePlaceTo(data)
    });

    bus.$on("changeSearchParams", (data) => {
      this.changePlaceTo(data.place_to)
      this.changePlaceFrom(data.place_from)
      this.changeUnitCode(data.unit_code)
      this.changeDate(data.on_date)
    });

    bus.$on("change_date", (data) => {
      this.changeDate(data)
    });

    this.getCurrentSearchParams()
  },

  updated(){
    this.setCurrentSearchParams()
  },
  
  methods: {
    checkLang(str) {
      clearTimeout(this._timerId)
      
      if (str.length > 0) {
        if (/[а-я]/i.test(str) == true) {
          this.lang = "ru"
        } else {
          this.lang = "en"
        }
      }
  
      this._timerId = setTimeout(() => {
        this.searchInput = str
      }, 10)
    },
    
    handleResize() {
      this.windowWidth = window.innerWidth
    },
  
    getSearchParamsHistory() {
      this.$postapi(this.$address + this.$getters.getSearchParamsHistory.uri, {
        method: this.$getters.getSearchParamsHistory.name,
        search_type: this.searchType,
        token: this.$_getsetting('token')
      }).then(data => {
        if (data.error == 0) {
          this.historyString =JSON.parse(data.message.replace(/:"f",/gm, `:false,`).replace(/:"t",/gm, `:true,`));
          this.historyData = JSON.parse(this.historyString[0].result)
        }
      })
    },
    
    getreferencedata(referencename) {
      this.$postapi(this.$address + this.$getters.getReferenceData.uri, {
        method: this.$getters.getReferenceData.name,
        reference_name: referencename
      }).then(data => {
        if (data.error == 0) {
          switch (referencename) {
            case 'lgst_place_from_wg':
              this.places_from = JSON.parse(data.message.replace(/:"f",/gm, `:false,`).replace(/:"t",/gm, `:true,`));
              break
            case 'lgst_place_to_wg':
              this.places_to = JSON.parse(data.message.replace(/:"f",/gm, `:false,`).replace(/:"t",/gm, `:true,`));
              break
            case 'lgst_cont_types':
              this.unit_codes = JSON.parse(data.message.replace(/:"f",/gm, `:false,`).replace(/:"t",/gm, `:true,`));
              break
          }
        } else {
          bus.$emit("show_notify", {
            color: "error",
            notifytext: data.message
          });
        }
      });
    },
    
    formatDate(date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${day}.${month}.${year}`
    },
    
    parseDate(date) {
      if (!date) return null
      const [day, month, year] = date.split('.')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    
    searchFromHistory(index) {
      this.changePlaceFrom(this.historyData[index].places_from[0].id)
      this.place_to = this.historyData[index].places_to[0]
      this.unit_code = this.historyData[index].unit_type[0]
      this.changeUnitCode(this.historyData[index].unit_type[0].id)
      this.showHistory = false
      this.search()
    },
    
    search() {
      bus.$emit('show_one_rate')
      setTimeout(this.sendRequest, 500);
      // this.getSearchParamsHistory()
    },
    
    clickOutsideHistory() {
      this.showHistory = false
    },
    
    sendRequest() {
      if (this.dateFormatted === null) {
        this.dateFormatted = this.formatDate(this.on_date)
      }
      bus.$emit("getratelist", {
        place_from: this.place_from ? this.place_from.id : null,
        place_to: this.place_to ? this.place_to.id : null,
        unit_code: this.unit_code ? this.unit_code.id : null,
        on_date: this.on_date,
        place_from_name: this.place_from ? this.place_from.name : null,
        place_to_name: this.place_to ? this.place_to.name : null,
        unit_code_name: this.unit_code ? this.unit_code.name : null,
        client_id: (this.cId == -5) ? this.client_id : this.cId,
      });

      this.getHolidaysInfo()
    },
  
    changePlaceFrom(data) {
      let place = this.places_from.find(item => item.id == data);
      this.place_from = place
    },
  
    changePlaceTo(data) {
      let place = this.places_to.find(item => item.id == data);
      this.place_to = place
    },
    
    changeUnitCode(data) {
      let unit = this.unit_codes.find(item => item.id == data);
      this.unit_code = unit
    },
  
    changeDate() {
      const nextMonth = new Date().getMonth() + 2
      let [day, month, year] = this.dateFormatted.split('.')
      if (nextMonth > 9) {
        month = nextMonth
      } else {
        month = '0' + nextMonth
      }
       day = '01'
      this.dateFormatted = `${day}.${month}.${year}`
      this.on_date = this.parseDate(this.dateFormatted)
    },
  
    reachYMGoal() {
      this.$metrika.reachGoal('Search')
    },

    getCurrentDay(){
      return new Date().toISOString().substring(0, 10)
    },

    getHolidaysInfo(){
      this.$postapi(this.$address + this.$getters.getHolidaysByCountry.uri, {
        method: this.$getters.getHolidaysByCountry.name,
        v_place_id: this.place_from.id,
      })
      .then(data => {
        if (data.error === 0) {
          this.holidaysData = JSON.parse(
            JSON.parse(data.message)[0].result
          ).filter((item) => {
            if (item.country_id == this.place_from.country_id) {
              return item
            }
          });
        }
      })
    },

    getCurrentSearchParams(){
      switch(this.currentUserId){
        case -1:
          if(this.$_getsetting('search-manager')){
            this.searchParams = JSON.parse(this.$_getsetting('search-manager'))
          }
        break;
        case -99:
          if(this.$_getsetting('search-nonauth')){
            this.searchParams = JSON.parse(this.$_getsetting('search-nonauth'))
          }
        break;
        default:
        if(this.$_getsetting('search-default')){
          this.searchParams = JSON.parse(this.$_getsetting('search-default'))
        }
        break;
      }

      this.place_from = this.searchParams.place_from
      this.place_to = this.searchParams.place_to
      this.unit_code = this.searchParams.unit_code

      if(+this.searchParams.on_date.slice(-2) >= new Date().getDate()){
        this.on_date = this.searchParams.on_date
      }else{
        this.searchParams.on_date = this.on_date
      }
    },

    setCurrentSearchParams(){
      this.searchParams.place_from = this.place_from
      this.searchParams.place_to = this.place_to
      this.searchParams.unit_code = this.unit_code
      this.searchParams.on_date = this.on_date

      switch(this.currentUserId){
        case -1:
          this.$_setsetting('search-manager', JSON.stringify(this.searchParams))
          break;
        case -99:
          this.$_setsetting('search-nonauth', JSON.stringify(this.searchParams))
          break;
        default:
          this.$_setsetting('search-default', JSON.stringify(this.searchParams))
          break;
      }
    },
  },
}
</script>

<template>
  <div>
  <v-form
    class="g-row"
    @submit.prevent="search()"
  >
    <div class="first-input-container g-col-lg-4 g-col-md-5 g-col-sm-4 g-row ">
      <v-autocomplete
        class="rate-panel__input rate-panel__input-first custom-label-color g-col-xs-2 g-col-sm-2"
        v-model="place_from"
        :height="windowWidth > 743 ? '52px' : '56px'"
        color="var(--main-orange)"
        solo
        clearable
        flat
        return-object
        label="Место отправки"
        :item-text="lang === 'en' ? 'name' : 'alt_name'"
        :disabled="isUpdating"
        :items="places_from"
        :search-input.sync="searchInput"
        item-color="var(--main-orange)"
        @update="changePlaceFrom"
      >
        
        <template v-slot:item="data">
          <template>
            <v-list-item-content>
              <v-list-item-title>{{lang === 'en' ? data.item.name : data.item.alt_name}}
                ({{lang === 'en' ? data.item.alt_name : data.item.name}})</v-list-item-title>
              <v-list-item-subtitle>{{data.item.group}}</v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </template>
      </v-autocomplete>
      
      <v-autocomplete
        class="rate-panel__input rate-panel__input-first custom-label-color  g-col-xs-2 g-col-sm-2"
        :height="windowWidth > 743 ? '52px' : '56px'"
        v-model="place_to"
        :disabled="isUpdating"
        :items="places_to"
        color="var(--main-orange)"
        solo
        clearable
        flat
        label="Место доставки"
        item-text="name"
        return-object
        item-color="var(--main-orange)"
      >
        <template v-slot:item="data">
          <template v-if="typeof data.item !== 'object'">
            <v-list-item-content v-text="data.item"></v-list-item-content>
          </template>
          <template v-else>
            <v-list-item-content>
              <v-list-item-title v-html="data.item.name"></v-list-item-title>
              <v-list-item-subtitle v-html="data.item.group"></v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </template>
      </v-autocomplete>
    </div>
    
    <v-autocomplete
      class="rate-panel__input custom-label-color g-col-xs-2 g-col-md-2 g-col-lg-2 g-col-sm-2"
      :height="windowWidth > 743 ? '52px' : '56px'"
      v-model="unit_code"
      :disabled="isUpdating"
      :items="unit_codes"
      solo
      return-object
      color="var(--main-orange)"
      clearable
      flat
      label="Оборудование"
      item-text="name"
      item-color="var(--main-orange)"
    >
      <template v-slot:item="data">
        <template v-if="typeof data.item !== 'object'">
          <v-list-item-content v-text="data.item"></v-list-item-content>
        </template>
        <template v-else>
          <v-list-item-content>
            <v-list-item-title v-html="data.item.name"></v-list-item-title>
            <v-list-item-subtitle v-html="data.item.group"></v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </template>
    </v-autocomplete>
    <div class="rate-panel__input rate-panel__input-date custom-label-color g-col-xs-2 g-col-md-2 g-col-lg-2 g-col-sm-2 margin-none">
      <v-menu
        ref="menu1"
        v-model="menu1"
        :close-on-content-click="false"
        transition="scale-transition"
        color="var(--main-orange)"
        offset-y
        max-width="290px"
        min-width="auto"
        justify="center"
        :left="windowSize < 800"
      >
        <template v-slot:activator="{ on, attrs }">
          <!--         <span>Дата готовности груза</span>-->
          <v-text-field
            :height="windowWidth > 743 ? '52px' : '56px'"
            v-model="dateFormatted"
            prepend-inner-icon="mdi-calendar"
            label="Дата выхода"
            color="var(--main-orange)"
            solo
            flat
            v-bind="attrs"
            @blur="on_date = parseDate(dateFormatted)"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          color="var(--main-orange)"
          v-model="on_date"
          first-day-of-week="1"
          no-title
          @input="menu1 = false"
          :min="getCurrentDay()"
        ></v-date-picker>

      </v-menu>
      <PublicHolidaysBtn v-if="holidaysData.length > 0" class="rate-panel__date-holidays" :holidays="holidaysData"/>
    </div>

  
    
    <div class="rate-panel__history-container g-col-xs-2 g-col-md-3 g-col-lg-2 g-col-sm-2" v-if="historyData.length > 0">
      <div class="rate-panel__btn-container g-col-xs-2 g-col-md-3 g-col-lg-2 g-col-sm-2" >
        <v-btn
          class="rate-panel__btn"
          active-class="rate-panel__btn"
          color="var(--main-orange)"
          depressed
          width="calc(100% - 42px)"
          type="submit"
          @click="reachYMGoal"
        >
          Найти варианты
        </v-btn>
        
        <div class="rate-panel__btn-divider"></div>
        
        <v-btn
          class="rate-panel__btn-menu"
          :class="{'rate-panel__btn-menu': true}"
          active-class="rate-panel__btn-menu"
          color="var(--main-orange)"
          depressed
          rounded
          type="button"
          @click="showHistory = !showHistory"
        >
          <v-icon v-if="showHistory == false">
            mdi-menu-down
          </v-icon>
          
          <v-icon v-if="showHistory == true">
            mdi-menu-up
          </v-icon>
        </v-btn>
      </div>
      
      <div
        class="rate-panel__history"
        v-if="showHistory === true"
        v-click-outside="clickOutsideHistory"
      >
        <HistoryRateSearch
          class="rate-panel__history-list"
          @search="searchFromHistory($event)"
          :histories="historyData"
        />
      </div>
    </div>
    
    <v-btn
      v-else
      class="rate-panel__btn rate-panel__btn-rounded g-col-xs-2 g-col-md-3 g-col-lg-2 g-col-sm-2"
      color="var(--main-orange)"
      dark
      depressed
      rounded
      large
      type="submit"
      @click="reachYMGoal"
    >
      <v-icon left>
        mdi-magnify
      </v-icon>
      Найти варианты
    </v-btn>
  </v-form>
  
  </div>
</template>

<style scoped lang="scss">
.rate-panel {
  
  &__input {
    height: 52px !important;
    border-radius: 2px;
    //position: relative;
    display: block;
    
    @media (min-width: $tablet-width) {
      &:not(:last-child) {
        @include right-gap;
      }
    }
    
    @media (max-width: $mobile-width) {
      margin-top: $size-20;
      height: 56px !important;
    }
    
    &-content {
      //max-width: 20px !important;
      position: relative;
      max-width: 50% !important;
    }
    
    &-first {
      width: calc(50% - 64px) !important;
      
      @media (max-width: $mobile-width) {
        width: 100% !important;
      }
    }
  }
  
  &__history-container {
    height: auto;
    
    @media (min-width: 744px) and (max-width: 1201) {
      margin-left: 50%;
    }
    
    @media (max-width: $tablet-width) {
      margin-top: $size-16;
      width: 100%;
    }
  }
  
  &__btn-container {
    position: relative;
    display: flex;
    width: 100%;
    
    @media (min-width: 744px) and (max-width: $tablet-width) {
      align-items: flex-end;
      left: calc(50% + 16px);
      width: calc(50% - 16px);
    }
  }
  
  &__btn {
    height: 52px !important;
    color: white;
    display: flex;
    align-items: center;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    border-radius: 30px 0 0 30px;

    @include font-body-bold-3;
    
    &-rounded {
      border-radius: 30px;
      
      @media (min-width: 744px) and (max-width: $tablet-width) {
        margin-left: calc(75% + 16px);
        width: calc(25% - 16px);
        font-size: 12px;
      }
      
      @media (max-width: $tablet-width) {
        margin-top: $size-24;
        height: 56px !important;
      }
    }
  }
  
  &__btn-divider {
    background: #FFFFFF;
    height: 52px;
    width: 2px;
    
    @media (max-width: 1000px) {
    }
  }
  
  &__btn-menu {
    height: 52px !important;
    color: white;
    min-width: 40px !important;
    align-items: center;
    display: flex;
    border-radius: 0 30px 30px 0;
    padding: 0 !important;
    
    @media (max-width: 1000px) {
    }
  }
  
  &__history {
    position: static;
    z-index: 99;
    top: 10%;
    margin-top: 5px;
    right: 10px;
    width: 100%;
    display: inline-block;
    max-width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    contain: content;
    
    @media (max-width: 1000px) {
      right: 0;
      width: 100%;
    }
    
    &-list {
      width: auto;
      position: static;
    }
  }
}

.first-input-container {
  
  @media (max-width: 743px) {
    width: 100%;
  }
}

.margin-none {
  @media (max-width: $tablet-width) {
    margin-right: 0 !important;
  }
}

//

.v-application--is-ltr .v-messages {
  color: #FFFFFF !important;
}

.rate-panel__input-date{
  position: relative;

  @media (max-width: $tablet-width) {
    margin-bottom: 20px;
  }

  @media (max-width: $mobile-width){
    position: initial;
  }
}

.rate-panel__date-holidays{
  margin-top: -25px;
}
</style>
