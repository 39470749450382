<script>
import VideoCard from "./VIdeoInstructionCard";
export default {
  components: {
    VideoCard
  },

  data() {
    return {
      windowWidth: window.innerWidth,
      displayedVideoCount: 3,

      isOpenVideos: false,
      message: "Посмотреть все",

      videoInstructions: [
        {
          id: 7,
          title: "Регистрация на Vedexx",
          subtitle: "Создание учётной записи и добавление информации",
          videoUrl: "I1_registration.mp4",
          poster: "instruction_cover1.webp",
        },
        {
          id: 8,
          title: "Работа со ставками",
          subtitle: "Поиск и фильтрация ставок",
          videoUrl: "I2_rates.mp4",
          poster: "instruction_cover2.webp",
        },
        {
          id: 9,
          title: "Оформление заявки на буккинг",
          subtitle: "Заполнение формы для создания заявки",
          videoUrl: "I3_add_rate.mp4",
          poster: "instruction_cover3.webp",
        },
        {
          id: 10,
          title: "Управление личным кабинетом Vedexx",
          subtitle: "Работа с профилем и личным кабинетом",
          videoUrl: "I4_archive.mp4",
          poster: "instruction_cover4.webp",
        },
        {
          id: 11,
          title: "Подписка по маршруту",
          subtitle: "Как отслеживать изменения в ставках",
          videoUrl: "I5_subscription.mp4",
          poster: "instruction_cover5.webp",
        },
      ],
    };
  },

  mounted() {
    window.addEventListener("load", this.handleResize)
    this.handleResize()
  },
  methods: {
    handleResize() {
      if (this.windowWidth < 1201 && this.windowWidth > 743) {
        this.displayedVideoCount = 2
      } else if (this.windowWidth < 744) {
        this.displayedVideoCount = 1
      }else{
        this.displayedVideoCount = 3
      }
    },
    openVideos() {
      if (!this.isOpenVideos) {
        this.displayedVideoCount = this.videoInstructions.length
        this.isOpenVideos = true
        this.message = "Скрыть"
      } else {
        this.handleResize()
        this.isOpenVideos = false
        this.message = "Посмотреть все"
      }
    },
  },
  computed: {
    videosList() {
      return this.videoInstructions.slice(0, this.displayedVideoCount)
    },
  }
};
</script>

<template>
  <div class="video-list g-row">
    <VideoCard
      v-for="video in videosList"
      :key="video.id"
      :video="video"
      class="video-list__card g-col-lg-4 g-col-md-4 g-col-sm-4 g-col-xs-2"
    />

    <div
      class="video-list__show-all"
      :class="
        isOpenVideos ? 'video-list__show-all-up' : 'video-list__show-all-down'
      "
      @click="openVideos"
    >
      {{ message }}
    </div>
  </div>
</template>

<style scoped lang="scss">
.video-list {
  margin-top: 20px;
  width: 100%;
  margin-bottom: 60px;
  &__card {
    margin-bottom: $size-32;

    &:last-child {
      margin-bottom: $size-24;
    }

    @media (min-width: $desktop-width) {
      &:nth-child(3n) {
        margin-right: 0;
      }
    }
  }
  

  &__show-all {
    display: flex;
    width: 100%;
    color: black;
    align-items: flex-end;
    justify-content: flex-end;
    text-align: center;
    line-height: 20px;
    cursor: pointer;
    z-index: 2;

    @include right-gap;
    @include font-description-semibold-0;

    &:hover {
      text-decoration: underline;
    }

    &-down {
      display: flex;

      &:after {
        content: url("https://api.iconify.design/iconamoon/arrow-down-2-duotone.svg?width=20&height=20");
        text-align: center;
        line-height: 10px;
      }
    }

    &-up {
      display: flex;

      &:after {
        content: url("https://api.iconify.design/iconamoon/arrow-up-2-duotone.svg?width=20&height=20");
        text-align: center;
        line-height: 10px;
      }
    }
  }
}
</style>
