<script>
export default {
  name: "PublicHolidaysBtn.vue",
  props:{
    holidays: Array,
  },

  data() {
    return {
      show: false,
    };
  },

  methods: {
    showList() {
      return this.show = true
    },

    hideList() {
      return this.show = false
    },
  },
};
</script>
<template>
  <div class="holidays">
    <p class="holidays__title" @click="showList">
      Праздничные дни
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 16.4355V16.4444M12 7.55556V13.7778M20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12Z"
          stroke="#E40101"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </p>
    <div v-if="show" class="holidays__list">
      <p class="holidays__list-title">Праздничные дни:</p>
      <img
        class="holidays__list-close"
        src="../../assets/img/icons/cancel.svg"
        alt="close"
        @click="hideList"
      />
      <div
        v-for="(holiday, index) of holidays"
        :key="index"
        class="holidays__list-item"
      >
        <p class="holidays__item-dates">{{ holiday.date_from}} - {{ holiday.date_to }}</p>
        <span class="holidays__item-name">{{ holiday.holiday_name }}</span>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.holidays {
  position: relative;
  width: fit-content;

  @media (max-width: $mobile-width) {
    position: initial;
  }

  &__title {
    display: flex;
    gap: 0 5px;
    align-items: center;
    color: var(--grey-10);
    text-decoration: underline;
    cursor: pointer;
    z-index: 100;
    position: relative;

    @include font-subtitle-regular;

    & > svg {
      path {
        stroke: var(--grey-10);
      }
    }
  }

  &__list {
    position: absolute;
    top: 30px;
    z-index: 100;

    border-radius: 8px;
    border: 2px solid var(--grey-50);
    background: #fff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
    padding: 18px 16px 16px 24px;

    display: flex;
    flex-direction: column;
    gap: 8px;

    @media (max-width: $tablet-width) {
      right: 0;
    }

    @media (max-width: $mobile-width) {
      right: unset;
      width: 100%;
      height: fit-content;
    }

    &-close {
      position: absolute;
      top: 16px;
      right: 18px;
      cursor: pointer;
    }

    &-item {
      @include font-description-semibold-1;
      display: flex;
      width: max-content;
      gap: 0 5px;

      @media (max-width: $mobile-width){
        flex-direction: column;
        gap: 12px 0;
      }
    }

    &-title {
      @include font-description-semibold-0;
    }
  }

  &__item {
    &-name {
      font-weight: 400;
    }
  }
}
</style>