<script>
import {getCookie, setCookie} from "@/utils/cookie";

export default {
  name: 'BannerCard',
  
  props: {
    banner: {
      type: Object,
      required: true
    }
  },
  
  data () {
    return {
      progress: 0,
      duration: 3,
      animationInterval: null,
      
      closeIcon: require("../../assets/img/icons/close-blue.svg"),
      backgroundImage: require("../../assets/img/Instruction_TS.png"),
    }
  },
  
  mounted() {
   this.startLoading()
  },
  
  methods: {
    startLoading() {
      this.progress = 0;
      const step = 100 / (this.duration * 10);
      
      this.animationInterval = setInterval(() => {
        this.progress += step;
        
        if (this.progress >= 100) {
          clearInterval(this.animationInterval);
          this.$emit('closeBanner')
        }
      }, 100);
    },
    
    openBannerLink(banner) {
      this.saveViewedBannerId(banner.id)
      window.open(banner.properties.url, '_blank')
    },

    saveViewedBannerId(bannerId) {
      const viewedBannerIdsString = getCookie('viewedBannerIds');
      let viewedBannerIds = []
      if (viewedBannerIdsString) {
        viewedBannerIds = JSON.parse(viewedBannerIdsString);
      }
      if (!viewedBannerIds.includes(bannerId)) {
        viewedBannerIds.push(bannerId);
        setCookie('viewedBannerIds', JSON.stringify(viewedBannerIds), 1); // 1 день
      }
    },
  }
}
</script>

<template>
  <div class="banner-card" :style="{ 'background-image': 'url(' + $api_content + banner.properties.images.image_big + ')' }">
    <div class="banner-card__close">
      <button
        class="banner-card__close-btn"
        v-ripple
        @click="$emit('closeBanner')"
      >
        <img class="banner-card__close-icon" alt="close icon" :src="closeIcon"/>
      </button>
    </div>
    <div class="banner-card__content">
      <div class="banner-card__content__progress" v-if="progress < 100">
        <div class="banner-card__content__progress-title">Идёт загрузка</div>
        <v-progress-linear
          color="#A4B5CC"
          class="banner-card__content__progress-bar"
          rounded
          height="8px"
          :value="progress"
        />
      </div>
      <div class="banner-card__content-title" v-html="banner.properties.title"></div>
      <div class="banner-card__content-text" v-html="banner.properties.description"></div>
      <button class="banner-card__content-btn" v-ripple @click="openBannerLink(banner)">
        {{banner.properties.button_text}}
      </button>
    </div>
  </div>
</template>

<style scoped lang="scss">
.banner-card {
  width: 960px;
  height: 720px;
  border-radius: 32px;
  position: relative;
  object-fit: cover;
  background-size: cover;
  margin: auto;
  
  @media (max-width: $tablet-width) {
    width: 680px;
    height: 872px;
  }
  
  @media (max-width: $mobile-width) {
    width: 100%;
    height: 100%;

    background-size: cover;
    background-position: center center;

    border-radius: unset;
  }
  
  &__close {
    right: 36px;
    position: absolute;
    top: 36px;
    height: 24px;
    
    &-btn {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background: rgba(255, 255, 255, .5);
      display: flex;
      justify-content: center;
      align-items: center;
      
      > img {
        width: 8px;
        height: 8px;

        @media (max-width: $mobile-width){
          width: 12px;
          height: 12px;
        }
      }
    }

    @media (max-width: $mobile-width){
      top: 20px;
      right: 20px;
    }
  }
  
  &__content {
    background: var(--blue-100);
    border-radius: 32px;
    padding: 32px 28px 56px;
    width: 100%;
    height: auto;
    position: absolute;
    bottom: 0;
    font-family: 'Montserrat', sans-serif;
    text-align: center;
    color: #FFFFFF;
    display: flex;
    flex-direction: column;
    justify-content: center;
    
    @media (max-width: $mobile-width) {
      padding: 24px 20px 40px;
      border-radius: 32px 32px 0 0;
    }
    
    &__progress {
      display: flex;
      width: 100%;
      align-items: center;
      
      &-title {
        font-family: 'Manrope', sans-serif;
        font-size: 14px;
        font-weight: 600;
        line-height: 16px;
        letter-spacing: 0.0089em;
        text-align: left;
        width: 120px;
        margin-right: 8px;
        
        @media (max-width: $mobile-width) {
          display: none;
        }
      }
    }
    
    &-title {
      font-size: 36px;
      font-weight: 700;
      line-height: 42px;
      margin-top: $size-40;
      text-transform: uppercase;
      
      @media (max-width: $mobile-width) {
        font-size: 24px;
        line-height: 32px;
        margin-top: $size-32;
      }
    }
    
    &-text {
      margin-top: $size-16;
      font-size: 24px;
      font-weight: 500;
      line-height: 130%;
      
      @media (max-width: $mobile-width) {
        font-size: 16px;
      }
    }
    
    &-btn {
      font-size: 20px;
      font-weight: 700;
      line-height: 20px;
      letter-spacing: 1px;
      text-align: center;
      color: var(--grey-100);
      width: auto;
      margin: $size-40 auto 0;
      background: var(--orange-50);
      border-radius: 16px;
      padding: 24px 32px;
      
      @media (max-width: $mobile-width) {
        margin-top: $size-32;
      }
    }
  }
}
</style>
