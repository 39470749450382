
<script>

export default {
  name: 'Video',
  props: {
    video: {
      type: [Array, Object]
    }
  },
  
  data () {
    return {
      videoPlay: false,
      videoPlayed: false,
    }
  },
  
  methods: {
    playPause() {
      if (this.videoPlay == false) {
        document.getElementById(this.video.id).play()
        this.videoPlay = true
        this.videoPlayed = true
      } else {
        document.getElementById(this.video.id).pause()
        this.videoPlay = false
      }
    },
    
    showCover() {
      this.videoPlayed = false
      this.videoPlay = false
    }
  },
  
  mounted () {
    document.getElementById(this.video.id).addEventListener('ended', this.showCover);
  }
}
</script>

<template>
  <div
    class="card"
  >
    <div class="card__controls" @click="playPause">
      <v-btn
        :class="videoPlay === true ? 'card__controls-play-active' : ''"
        class="card__controls-play "
        color="#0006"
        elevation="0"
        fab
        dark
      >
        <v-icon size="25px" v-if="videoPlay === false">
          mdi-play
        </v-icon>
        <v-icon size="25px" v-else>
          mdi-pause
        </v-icon>
      </v-btn>
    </div>

    <video
      playsinline
      class="card__video"
      :src="`/video/${video.videoUrl}`"
      :id="`${video.id}`"
      :poster="`/video/${video.poster}`"
      :controls="videoPlay"
      @click="playPause"
    >
    </video>
    
    <div class="card__ttl">
      {{video.title}}
    </div>
    
    <div class="card__sub-ttl">
      {{video.subtitle}}
    </div>
    
    <div
      v-if="!videoPlay && videoPlayed"
      class="card__cover"
      style="background: none;"
      @click="playPause"
    ></div>
  </div>
</template>

<style lang="scss" scoped>
.card {
  border: 0.5px solid #9E9E9E;
  border-radius: 4px;
  cursor: pointer;
  transition-duration: 0.3s;
  height: auto;
  padding-bottom: 15px;
  display: inline;
  
  @media (max-width: 744px) {
    border-radius: 11px;
  }
  
  &__video {
    padding-left: 0 !important;
    padding-right: 0 !important;
    height: 220px;
    object-fit: cover;
    z-index: 1;
    position: relative;
    top: 0;
    -o-object-fit: cover;
  
    @media (max-width: 744px) {
      height: 220px;
      border-radius: 11px 11px 0 0;
    }
  }
  
  &__ttl {
    @include font-headline-2;

    color: #050505;
    margin: 10px 20px 5px;
  }
  
  &__sub-ttl {
    @include font-subtitle-regular;

    letter-spacing: 0.25px;
    color: #3E3E3E;
    margin: 0 20px;
  }
  
  &__controls {
    position: absolute;
    height: 50px;
    width: 32%;
    //margin-left: calc(15% / 2);
    //margin-left: calc(-11% / 2);
    display: flex;
    margin-top: 85px;
    z-index: 10;
    
    @media (max-width: 1919px) {
      //margin-left: -2%;
    }
    
    @media (max-width: $tablet-width) {
      margin-left: calc(15% / 2);
    }

    @media (max-width: 744px) {
      width: 20%;
      position: relative;
      height: 0;
      margin: 0 40%;
      z-index: 3;
    }
    
    &-play {
      position: relative;
      margin: auto;
      border-radius: 50%;
      z-index: 100;
  
      @media (max-width: 744px) {
        top: 105px;
      }
      
      &-active {
        display: none;
      }
    }
  }
  
  &__cover {
    position: absolute;
    height: 220px;
    background: none;
    top: 0;
    
    &-text {
      left: 15%;
      top: calc(50% - 50px);
      max-width: 410px;
      position: relative;
      
      > h2 {
        font-weight: 800;
        font-size: 32px;
        line-height: 43px;
        color: #FFFFFF;
      }
      
      > h3 {
        font-weight: 400;
        font-size: 20px;
        line-height: 26px;
        color: #FFFFFF;
      }
    }
  }
}

video {
  object-fit: cover !important;
  padding-right: 0 !important;
  padding-left: 0 !important;
  width: 100% !important;
  height: 220px !important;
  
  @media (max-width: 744px) {
    height: 250px !important;
  }
}
</style>

